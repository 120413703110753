import { Signal, computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Observable, isObservable } from 'rxjs';

/**
 * This function gives a default value to any signal or observable.
 *
 * If an Observable is passed in, it will be converted to a Signal.
 */
export function withDefault<T>(
  signalOrObservable: Signal<T> | Observable<T>,
  defaultValue: T
) {
  const signal = isObservable(signalOrObservable)
    ? toSignal(signalOrObservable)
    : signalOrObservable;

  return computed(() => signal() ?? defaultValue);
}
